<template>
  <main class="results">
    <PageHeader :title="`${gameName} - Match Results`"></PageHeader>
    <h1>Match results</h1>
    <l-loading v-model="loading">
      <section class="results" v-if="results">
        <CopsMatchPreview state="results" v-for="match in results" :key="match._id" :match="match"></CopsMatchPreview>
      </section>
    </l-loading>
  </main>
</template>

<script>
import axios from 'axios';
import CopsMatchPreview from "@/components/c-ops/copsMatchPreview.vue";
export default {
  components: {
    CopsMatchPreview
  },
  data: () => ({
    results: null
  }),
  computed: {
    gameName() {
      return this.$games.find(game => game.slug === this.$store.state.game).name;
    },
    loading() {
      return this.results !== null;
    },
  },
  created() {
    axios.get(this.$api('/matches/results'))
    .then(res => {
      this.results = res.data.docs;
    })
    .catch(err => {
      alert(err);
    });
  }
}
</script>

<style>

</style>